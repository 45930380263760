import { Fragment, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Accordion, Button, Card, Container, Tab, Tabs } from 'react-bootstrap'
import md5Hash from 'crypto-js/md5'

import { MapPanelSettings, Panel } from '../../types/Panel'
import { PanelType } from '../../types/PanelType'

import { CloudContext, ConnectionState } from '../../services/LicenseService'

import AddPanelCard from '../../components/cards/AddPanelCard'
import FrameParentPanel from '../../components/cards/SignalVisualizationPanelCard'
import MainCard from '../../components/cards/PlaybackAndRecordCard'
import ForceRefreshModal from '../../components/modal/ForceRefreshModal'
import { Frame, PlaybackState, RecordingState } from '../../api/BrokerApi/types'
import { SavedDashboard } from '../../types/persistance/SavedTypes'
import { Configuration, LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import { randomString } from '../../utils/Random'
import { TabKey, TabKeyState } from '../../types/state/TabKeyState'
import { PanelFilter, Signal } from '../../types/Filters'
import PanelFilterModal from '../../components/modal/PanelFilterModal'
import StoredSettingsBanner from './StoredSettingsBanner'
import InformationModal from '../../components/modal/InformationModal'
import { getBrokerApiKey, getBrokerUrl } from '../../utils/broker'
import { isFrameIdentical } from '../../utils/FilterUtilities'
import VideoPanelCard from '../../components/cards/VideoPanelCard'
import monolithApi from '../../api/CloudApi'
import ListVideoModal from '../../components/cards/VideoPanelCard/ListVideoModal'
import { MediaFile } from '../../api/CloudApi/types'
import MapPanelCard from '../../components/cards/MapPanelCard'
import { formattedToastMessage } from '../../utils/toast'
import { isDemo, isIframe } from '../../utils/CloudDetails'
import {
    applyPendingSubscriptions,
    dropIncomingSignals,
    getAvailableFiles,
    getConfiguration,
    listSignals,
    registerCallback,
    subscribeToPlaybackState,
    subscribeToRecordingState,
} from '../../services/BrokerService'
import { ProductAnalyticsTracker } from '../../utils/ProductAnalytics'
import FrameDistributionCard from '../../components/cards/FrameDistributionCard'
import PageCard from '../../components/cards/PageCard'
import BrokerInformationFooter from '../../components/BrokerInformationFooter'
import LicenseFlowContainer from '../../components/LicenseFlowContainer'
import CliHintContainer from './CliHintContainer'
import ExampleCodeCard from './ExampleCodeCard'

interface DashboardProps {
    isAppInitialized: boolean
    isCloudBroker: boolean
    license: LicenseInfo | undefined
    isLicensed: boolean
    connectionState: ConnectionState
    setLicenseFunction: (license: LicenseInfo) => void
    isFluidDesign: boolean
    cloudContext: CloudContext | undefined
    useStoredSession: boolean
    setUseStoredSession: Function
    productAnalyticsTracker: ProductAnalyticsTracker
}

const COMMON_PANEL_STYLE = 'px-1'

function Dashboard(props: DashboardProps) {
    // Modals
    const [showForceRefreshModal, setShowForceRefreshModal] = useState(false)
    const [showSubscriptionChannelDisconnectedModal, setShowSubscriptionChannelDisconnectedModal] = useState(false)
    const [showStoredStateBanner, setShowStoredStateBanner] = useState<boolean>(props.useStoredSession)

    const [storedSessionExists, setStoredSessionExists] = useState<boolean>(false)

    const [playbackState, setPlaybackState] = useState<PlaybackState | undefined>(undefined)
    const [recordingState, setRecordingState] = useState<RecordingState>()
    const [frames, setFrames] = useState<Array<Frame>>([])
    const [tabKey, setTabKey] = useState<TabKey>(TabKey.RECORD_TAB_KEY)
    const [pauseLiveData, setPauseLiveData] = useState<boolean>(false)
    const [showPanelFilterModal, setShowPanelFilterModal] = useState(false)
    const [panels, setPanels] = useState<Array<Panel>>([])

    const [showListVideoModal, setShowListVideoModal] = useState(false)
    const [videoFiles, setVideoFiles] = useState<Array<MediaFile>>([])
    const cachedConfiguration = useRef<Configuration>()
    const cachedAllFrames = useRef<Array<Frame>>()
    const [latestCliVersion, setLatestCliVersion] = useState<string>()

    useEffect(() => {
        console.debug('Mounted dashboard')
        registerCallback(
            () => setShowForceRefreshModal(true),
            () => {
                setShowSubscriptionChannelDisconnectedModal(true)
            }
        )
    }, [])

    useEffect(() => {
        const isNoPanels = panels.length === 0
        if (isNoPanels) {
            checkStoredDashboardState()
        }
    }, [playbackState, panels])

    useEffect(() => {
        if (storedSessionExists) {
            if (props.useStoredSession || isIframe()) {
                createPanelsFromLocalStorage()
            } else {
                setShowStoredStateBanner(true)
            }
        }
    }, [storedSessionExists, props.useStoredSession])

    useEffect(() => {
        if (panels.length > 0) {
            applyPendingSubscriptions()
        }
    }, [panels, props.isAppInitialized])

    useEffect(() => {
        console.debug('Mounting playmode in dashboard')
        // No need to do this until licensed
        // TODO - when we get a new license, we are not aware of this here
        if (props.isLicensed && frames.length <= 0) {
            const fetchSignals = async () => {
                //                if (frames.length <= 0) {
                const allFrames = await getCachedAllFrames()
                if (allFrames.length > 0) {
                    setFrames(allFrames)
                }
                //                }
            }

            console.debug('Getting signal/frame metadata and subscribing to playback and record state')
            fetchSignals()
            subscribeToPlaybackState(setPlaybackState)
            subscribeToRecordingState(setRecordingState)
        }
    }, [frames, props.isLicensed])

    useEffect(() => {
        const doFetch = async () => {
            if (latestCliVersion === undefined) {
                if (props.connectionState.clientHasInternet) {
                    setLatestCliVersion(await fetchLatestCliVersion())
                }
            }
        }
        doFetch()
    }, [props.connectionState])

    const fetchLatestCliVersion = async () => {
        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 5000)

        try {
            const resp = await fetch('https://pypi.org/pypi/remotivelabs-cli/json', {
                signal: controller.signal,
                cache: 'no-store',
            })
            const cli_info = await resp.json()
            clearTimeout(timeoutId)
            return cli_info['info']['version']
        } catch (err) {
            clearTimeout(timeoutId)
            return undefined
        }
    }

    const allSignalsExist = async (dashboard: SavedDashboard) => {
        const allFrames = await getCachedAllFrames()
        const flattenedSignals = allFrames
            .map((frame) => [frame.name, frame.signals.map((signal) => signal.name)])
            .flat(2)
        const flattenedFilters = dashboard.panels
            .map((panel) => [
                panel.frameParentSettings?.frameFilter.frameName ?? [],
                panel.frameParentSettings?.signalFilters.map((signal) => signal.signalName) ?? [],
            ])
            .flat(2)
        return flattenedFilters.every((signal) => flattenedSignals.includes(signal))
    }

    const checkStoredDashboardState = async () => {
        try {
            const key = createSavedDashboardKey(await getCachedConfiguration())
            console.debug('Checking if there is a stored dashboard')
            if (!storedSessionExists && key !== undefined) {
                const savedDashboard = localStorage.getItem(key)
                if (savedDashboard !== null) {
                    try {
                        const dashboard = JSON.parse(savedDashboard) as SavedDashboard
                        setStoredSessionExists(dashboard.panels.length > 0 && (await allSignalsExist(dashboard)))
                    } catch (e) {
                        console.debug(`Stored dashboard JSON was corrupt, removing everything for ${key}`)
                        setStoredSessionExists(false)
                        props.setUseStoredSession(false)
                        localStorage.removeItem(key)
                    }
                } else {
                    setStoredSessionExists(false)
                }
            } else {
                console.debug('There is no key for this dashboard')
                props.setUseStoredSession(false)
                setStoredSessionExists(false)
            }
        } catch (error: any) {
            console.warn(`Failed to fetch broker configuration for Dashboard. Error: \n${JSON.stringify(error)}`)
            props.setUseStoredSession(false)
            setStoredSessionExists(false)
        }
    }

    const getCachedConfiguration = async () => {
        if (cachedConfiguration.current === undefined) {
            console.debug('No config cached, fetching config...')
            const config = await getConfiguration()
            cachedConfiguration.current = config
            return config
        }
        console.debug('Returning cached config!')
        return cachedConfiguration.current
    }

    const getCachedAllFrames = async () => {
        if (cachedAllFrames.current === undefined) {
            console.debug('No frames cached, fetching frames...')
            const allFramesAndSignals = await listSignals()
            cachedAllFrames.current = allFramesAndSignals
            return allFramesAndSignals
        }
        console.debug('Returning cached frames!')
        return cachedAllFrames.current
    }

    const createSavedDashboardKey = (config: Configuration) => {
        const playbackStateFiles = playbackState?.files()
        if (config) {
            if (playbackStateFiles) {
                return (
                    md5Hash(config.getInterfacesjson_asB64()) +
                    playbackStateFiles
                        .map((playbackFile) => playbackFile.namespace() + playbackFile.path())
                        .sort()
                        ?.toString()
                )
            } else {
                return md5Hash(config.getInterfacesjson_asB64()).toString()
            }
        }
        return undefined
    }

    const verifyPanelsAreValid = (panels: Array<Panel>) => {
        panels.forEach((panel) => {
            switch (panel.panelType) {
                case PanelType.FRAME_PARENT:
                    if (panel.frameParentSettings === undefined) {
                        throw Error('Frame panel type is missing settings')
                    }
                    break

                case PanelType.MAP:
                    if (panel.mapSettings === undefined) {
                        throw Error('Map panel type is missing settings')
                    }
                    break

                case PanelType.VIDEO:
                    if (panel.videoSettings === undefined) {
                        throw Error('Video panel type is missing settings')
                    }
                    break
            }
        })
    }

    const createPanelsFromLocalStorage = async () => {
        const key = createSavedDashboardKey(await getCachedConfiguration())
        if (key !== undefined) {
            try {
                const savedDashboard = localStorage.getItem(key)
                if (savedDashboard !== null) {
                    console.debug(`Creating panels from stored state`)
                    const dashboard = JSON.parse(savedDashboard) as SavedDashboard
                    const panelsToCreate = dashboard.panels.map((panel) => {
                        return {
                            ...panel,
                            // Random string is used to recreate all graphs when we add a new to make sure
                            // they are vertically synced
                            key: panel.panelType === PanelType.VIDEO ? panel.key : randomString(),
                        } as Panel
                    })
                    verifyPanelsAreValid(panelsToCreate)
                    setPanels(panelsToCreate.sort((a, b) => panelSortingPredicate(a, b)))
                } else {
                    console.debug(`No dashboard saved for key ${key}`)
                }
            } catch (e) {
                localStorage.removeItem(key)
                toast.error(
                    formattedToastMessage(
                        'Failed to recreate dashboard',
                        'The stored dashboard settings for this session have been reset'
                    )
                )
            }
        } else {
            console.debug(`Couldn't create key to recreate dashboard`)
        }
    }

    /* First we sort based on panel type, so all panels of the same type will be together.
    If it is the same panel type we sort based on when the panel was added */
    const panelSortingPredicate = (panel: Panel, panelToCompareWith: Panel) => {
        // Always sort signal visualization on top
        if (panel.panelType === PanelType.FRAME_PARENT) {
            return -1
        }
        if (panelToCompareWith.panelType === PanelType.FRAME_PARENT) {
            return 1
        }
        return (
            panel.panelType.localeCompare(panelToCompareWith.panelType) ||
            panel.addedTimestamp - panelToCompareWith.addedTimestamp
        )
    }

    const savePanelsInLocalStorage = async (panelsToSave: Array<Panel>) => {
        const dashboardToSave = JSON.stringify({
            panels: panelsToSave.sort((a, b) => panelSortingPredicate(a, b)),
            name: 'dashboard-name-placeholder',
        } as SavedDashboard)
        const key = createSavedDashboardKey(await getCachedConfiguration())
        if (key !== undefined) {
            localStorage.setItem(key, dashboardToSave)
            console.debug(`Saving panels in local storage! State is (key=${key}) as ${dashboardToSave}`)
        } else {
            console.debug(`Couldn't save dashboard, due to an error generating the key`)
        }
    }

    const removePanel = (panelKey: string) => {
        const panelsAfterRemoval = panels
            .filter((panel) => panel.key !== panelKey)
            .sort((a, b) => panelSortingPredicate(a, b))
        setPanels(panelsAfterRemoval)
        savePanelsInLocalStorage(panelsAfterRemoval)
    }

    const addFrameHistogramPanel = async () => {
        if (panels.find((panel) => panel.panelType === PanelType.FRAME_HISTOGRAM) === undefined) {
            const key = randomString()
            console.debug(`Adding frame histogram panel ${key}!`)
            const frameHistogramPanel = {
                name: 'Frame histogram panel',
                panelType: PanelType.FRAME_HISTOGRAM,
                addedTimestamp: Date.now(),
                key,
            } as Panel
            const newPanelsState = [...panels, frameHistogramPanel].sort((a, b) => panelSortingPredicate(a, b))
            setPanels(newPanelsState)
            savePanelsInLocalStorage(newPanelsState)
        } else {
            toast.error(
                formattedToastMessage(
                    'Only one frame histogram panel is allowed at a time',
                    'If you have a use case that requires multiple frame histograms in the same dashboard, please send an email to support@remotivelabs.com for further support.'
                )
            )
        }
    }

    const addMapPanel = async () => {
        if (panels.find((panel) => panel.panelType === PanelType.MAP) === undefined) {
            const key = randomString()
            console.debug(`Adding map panel ${key}!`)
            const mapPanel = {
                name: 'Map panel',
                panelType: PanelType.MAP,
                addedTimestamp: Date.now(),
                key,
                mapSettings: {} as MapPanelSettings,
            } as Panel
            const newPanelsState = [...panels, mapPanel].sort((a, b) => panelSortingPredicate(a, b))
            setPanels(newPanelsState)
            savePanelsInLocalStorage(newPanelsState)
        } else {
            toast.error(
                formattedToastMessage(
                    'Only one map panel is allowed at a time',
                    'If you have a use case that requires multiple maps in the same dashboard, please send an email to support@remotivelabs.com for further support.'
                )
            )
        }
    }

    const savePanelFunction = (panel: Panel) => {
        const index = panels.map((it) => it.key).indexOf(panel.key)
        const newPanels = [...panels]
        newPanels[index] = panel
        savePanelsInLocalStorage(newPanels)
    }

    const addVideoPanel = async () => {
        try {
            // should not be invoked if cloudcontext is not set
            const recordings = await monolithApi.getRecordingSession(
                props.cloudContext!.project,
                props.cloudContext!.recordingSession
            )
            setVideoFiles(recordings.data.mediaFiles.filter((file: MediaFile) => file.mimeType.startsWith('video')))
            setShowListVideoModal(true)
        } catch (error: any) {
            toast.error(showFetchFromCloudFailedErrorMessage(error), { autoClose: 10_000 })
        }
    }

    const saveNewVideoOffset = async (mediaFile: MediaFile) => {
        if (props.cloudContext) {
            await monolithApi.saveVideoOffset(
                props.cloudContext!.project,
                props.cloudContext!.recordingSession,
                mediaFile
            )
        } else {
            // This should not happen since access should have been prevented
            console.error('No cloud context present so its not possible to store new offset')
        }
    }

    const showFetchFromCloudFailedErrorMessage = (error: any) => {
        return (
            <>
                <p className="text-start m-0">
                    <b>There was an error trying fetch videos.</b> <br />{' '}
                    <span className="remotive-font-xs">
                        Please send an email to <b>support@remotivelabs.com</b> for further support.
                    </span>
                </p>
            </>
        )
    }

    const getPanels = () => {
        const createdPanels = panels
            .map((panel) => {
                switch (panel.panelType) {
                    case PanelType.FRAME_PARENT:
                        return (
                            <div className={`col-12 ${COMMON_PANEL_STYLE}`} key={panel.key}>
                                <FrameParentPanel
                                    isCloudBroker={props.isCloudBroker}
                                    savePanelFunction={(panel: Panel) => savePanelFunction(panel)}
                                    allFrames={frames}
                                    panelKey={panel.key}
                                    addedTimestamp={panel.addedTimestamp}
                                    panelTitle={panel.name}
                                    removePanelFunction={() => removePanel(panel.key)}
                                    frameFilter={panel.frameParentSettings!.frameFilter}
                                    signalFilters={panel.frameParentSettings!.signalFilters}
                                    optionalPanelSettings={panel.frameParentSettings!.optional}
                                    setPanelsFunction={(panels: Panel[]) => {
                                        const sortedPanels = panels.sort((a, b) => panelSortingPredicate(a, b))
                                        setPanels(sortedPanels)
                                        savePanelsInLocalStorage(sortedPanels)
                                    }}
                                    panels={panels}
                                    playbackState={playbackState}
                                    pauseLiveState={pauseLiveData}
                                />
                            </div>
                        )

                    case PanelType.VIDEO:
                        return (
                            <div className={`col-12 col-lg-6 ${COMMON_PANEL_STYLE}`} key={panel.key}>
                                <VideoPanelCard
                                    videoPanelSettings={panel.videoSettings!}
                                    panelTitle={panel.name}
                                    panelKey={panel.key}
                                    removePanelFunction={() => removePanel(panel.key)}
                                    playbackState={playbackState}
                                    saveOffsetFunction={(mediaFile: MediaFile) => {
                                        saveNewVideoOffset(mediaFile)
                                        savePanelFunction(panel)
                                    }}
                                    cloudContext={props.cloudContext}
                                />
                            </div>
                        )

                    case PanelType.MAP:
                        return (
                            <div className={`col-12 col-lg-6 ${COMMON_PANEL_STYLE}`} key={panel.key}>
                                <MapPanelCard
                                    allSignals={frames.flatMap((frame) => [
                                        { name: frame.name, namespace: frame.namespace } as Signal,
                                        ...frame.signals.map((signal) => {
                                            return { name: signal.name, namespace: frame.namespace }
                                        }),
                                    ])}
                                    savePanelFunction={(panel: Panel) => savePanelFunction(panel)}
                                    connectionState={props.connectionState}
                                    isCloudBroker={props.isCloudBroker}
                                    thisPanel={panel}
                                    removePanelFunction={() => removePanel(panel.key)}
                                />
                            </div>
                        )

                    case PanelType.FRAME_HISTOGRAM:
                        return (
                            <div className={`col-12 col-lg-6 ${COMMON_PANEL_STYLE}`} key={panel.key}>
                                <FrameDistributionCard
                                    pauseLiveState={pauseLiveData}
                                    playbackState={playbackState}
                                    allFrames={frames}
                                    panelKey={panel.key}
                                    savePanelFunction={(panel: Panel) => savePanelFunction(panel)}
                                    isCloudBroker={props.isCloudBroker}
                                    removePanelFunction={() => removePanel(panel.key)}
                                />
                            </div>
                        )

                    default:
                        console.debug(`Trying to create unsupported panel type ${panel.panelType}`)
                        return undefined
                }
            })
            .filter((panel) => panel !== undefined)
        const addPanelCard = (
            <div className="col-12" key={'STATIC_KEY_FOR_ADD_PANEL_CARD'}>
                <AddPanelCard
                    connectionState={props.connectionState}
                    isCloudBroker={props.isCloudBroker}
                    playbackState={playbackState}
                    openModalFunction={() => setShowPanelFilterModal(true)}
                    openAddVideoModalFunction={addVideoPanel}
                    addMapPanelFunction={addMapPanel}
                    addFrameHistogramFunction={addFrameHistogramPanel}
                    cloudContext={props.cloudContext}
                    productAnalyticsTracker={props.productAnalyticsTracker}
                />
            </div>
        )

        const showAddPanelCard =
            (tabKey !== TabKey.ABOUT_TAB_KEY && tabKey !== TabKey.CONFIG_TAB_KEY) || createdPanels.length > 0
        return [...createdPanels, showAddPanelCard && addPanelCard]
    }

    function getPauseLiveDataButton() {
        const text = pauseLiveData ? 'Defrost' : 'Freeze'
        const changeStateTo = !pauseLiveData
        return (
            !props.isCloudBroker && (
                <button
                    className={'btn remotive-btn-sm remotive-btn-primary'}
                    onClick={() => {
                        setPauseLiveData(changeStateTo)
                        dropIncomingSignals(changeStateTo)
                    }}
                >
                    <p className={'remotive-small m-0 p-0'}>{text}</p>
                </button>
            )
        )
    }

    const getMonitoringBar = () => {
        if (panels.length > 0) {
            return <div className={'d-flex justify-content-end d-inline mt-0'}>{getPauseLiveDataButton()}</div>
        }
        return <></>
    }

    const signalsToUseInExamples = (): Array<{ namespace: string; signals: Array<string> }> => {
        // First choice, we populate the example code with the same signals as the user has active in the dashboard panels
        if (panels.length > 0) {
            const namespaceToSignalsMap = new Map<string, Array<string>>()
            panels.forEach((panel) => {
                if (panel.panelType === PanelType.FRAME_PARENT) {
                    const namespace = panel.frameParentSettings!.frameFilter.namespace
                    const signals = panel.frameParentSettings!.signalFilters.map(
                        (signalFilter) => signalFilter.signalName
                    )
                    const previousSignals = namespaceToSignalsMap.get(namespace) || []
                    namespaceToSignalsMap.set(namespace, [
                        ...previousSignals,
                        ...signals,
                        panel.frameParentSettings!.frameFilter.frameName,
                    ])
                }
            })
            const namespaces = Array.from(namespaceToSignalsMap.keys())
            return namespaces.map((namespace) => {
                return {
                    namespace,
                    signals: namespaceToSignalsMap.get(namespace) || [],
                }
            })
        }
        // Fallback, if the users hasn't selected any signals we will pick the first available signal from the current configuration
        const firstSignalFromConfiguration = frames[0]
        if (firstSignalFromConfiguration !== undefined) {
            return [
                {
                    namespace: firstSignalFromConfiguration.namespace,
                    signals: [firstSignalFromConfiguration.name],
                },
            ]
        }

        // Last fallback, use hardcoded values
        return [{ namespace: 'REPLACE_WITH_NAMESPACE', signals: ['REPLACE_WITH_SIGNAL'] }]
    }

    const getMonitoringContainer = () => {
        return (
            <>
                <div className="mb-5">
                    <div className="px-0 w-100">{getMonitoringBar()}</div>
                    <div className="row">
                        {props.isLicensed && (
                            <>
                                <div className="d-flex justify-content-center">
                                    <StoredSettingsBanner
                                        show={showStoredStateBanner && panels.length === 0}
                                        handleCloseFunction={() => setShowStoredStateBanner(false)}
                                        removePanelsFromStorageFunction={() => savePanelsInLocalStorage([])}
                                        setUseStoredSession={props.setUseStoredSession}
                                    />
                                </div>
                                {getPanels()}
                            </>
                        )}
                    </div>
                    <div className="mx-1 w-100">
                        <ExampleCodeCard
                            productAnalyticsTracker={props.productAnalyticsTracker}
                            connectionState={props.connectionState}
                            latestCliVersion={latestCliVersion}
                            signalsToUseInExamples={signalsToUseInExamples()}
                        />
                    </div>
                </div>
            </>
        )
    }

    const topCard = () => {
        if (!props.isLicensed) {
            return (
                <PageCard
                    body={
                        <LicenseFlowContainer
                            setLicenseFunction={props.setLicenseFunction}
                            isLicensed={props.isLicensed}
                            license={props.license}
                            connectionState={props.connectionState}
                        />
                    }
                />
            )
        }
        return (
            <MainCard
                isCloudBroker={props.isCloudBroker}
                isLicensed={props.isLicensed}
                recordingState={recordingState}
                listFilesFunction={getAvailableFiles}
                playbackState={playbackState}
                connectionState={props.connectionState}
                refreshPanels={() => setPanels([])}
                setLicenseFunction={props.setLicenseFunction}
                tabKeyState={{ tabKey, setTabKey } as TabKeyState}
                productAnalyticsTracker={props.productAnalyticsTracker}
            />
        )
    }

    const containerContent = () => {
        return (
            <>
                <Container fluid={props.isFluidDesign} className={`${props.isFluidDesign ? 'px-4' : ''}`}>
                    {topCard()}

                    <div className="row" id="panel-container-id">
                        {props.isLicensed &&
                            props.connectionState.clientIsConnectedToBroker &&
                            getMonitoringContainer()}
                    </div>

                    {/* Sticky broker info */}
                    <BrokerInformationFooter
                        isLicensed={props.isLicensed}
                        licenseInfo={props.license}
                        isAppInitialized={props.isAppInitialized}
                        isCloudBroker={props.isCloudBroker}
                        connectionState={props.connectionState}
                        cloudContext={props.cloudContext}
                    />

                    {/* Modals below */}
                    <PanelFilterModal
                        show={showPanelFilterModal}
                        allFrames={frames}
                        selectedPanelFilters={
                            panels
                                .map((panel) => {
                                    if (panel.panelType === PanelType.FRAME_PARENT) {
                                        return {
                                            frameFilter: panel.frameParentSettings!.frameFilter,
                                            signalFilters: panel.frameParentSettings!.signalFilters,
                                        } as PanelFilter
                                    }
                                    return undefined
                                })
                                .filter((panel) => panel !== undefined) as Array<PanelFilter>
                        }
                        handleCloseFunction={() => setShowPanelFilterModal(false)}
                        setPanelFiltersFunction={(panelFilters: PanelFilter[]) => {
                            const selectedPanels = panelFilters.map((panelFilter) => {
                                const renderedPanel = panels.find(
                                    (it) =>
                                        it.panelType === PanelType.FRAME_PARENT &&
                                        isFrameIdentical(panelFilter.frameFilter, it.frameParentSettings!.frameFilter)
                                )
                                // Generating a new random key will force React to mount a new panel component. This is required to keep the signal charts in sync when adding signals
                                const panelKey = randomString()
                                return {
                                    ...renderedPanel,
                                    name: panelKey,
                                    panelType: renderedPanel?.panelType || PanelType.FRAME_PARENT,
                                    addedTimestamp: renderedPanel?.addedTimestamp || Date.now(),
                                    key: panelKey,
                                    frameParentSettings: {
                                        ...renderedPanel?.frameParentSettings,
                                        signalFilters: panelFilter.signalFilters,
                                        frameFilter: panelFilter.frameFilter,
                                    },
                                } as Panel
                            })
                            setPanels(
                                [
                                    ...selectedPanels,
                                    ...panels.filter((panel) => panel.panelType !== PanelType.FRAME_PARENT),
                                ].sort((a, b) => panelSortingPredicate(a, b))
                            )
                        }}
                    />
                    <ForceRefreshModal
                        show={showForceRefreshModal}
                        handleCloseFunction={() => setShowForceRefreshModal(false)}
                    />
                    <InformationModal
                        show={showSubscriptionChannelDisconnectedModal && !showForceRefreshModal}
                        handleCloseFunction={() => setShowSubscriptionChannelDisconnectedModal(false)}
                        title="Signal subscription ended"
                        body={
                            'Browser stopped receiving signals from the broker. If this was unexpected you can try to refresh this window ' +
                            'but if this problem persist you may need to check you configuration and that your signal filters ' +
                            'match your configuration.'
                        }
                    />

                    <ListVideoModal
                        show={showListVideoModal}
                        savePanelFunction={(panel: Panel) => {
                            const p = [...panels]
                            p.push(panel)
                            const sortedPanels = p.sort((a, b) => panelSortingPredicate(a, b))
                            setPanels(sortedPanels)
                            savePanelsInLocalStorage(sortedPanels)
                        }}
                        handleCloseFunction={() => setShowListVideoModal(false)}
                        videoFiles={videoFiles}
                        cloudContext={props.cloudContext}
                    />
                </Container>
            </>
        )
    }

    return <>{props.isAppInitialized && containerContent()}</>
}

export default Dashboard
