import { Dropdown, InputGroup, Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { PanelType } from '../../../types/PanelType'
import { CloudContext } from '../../../services/LicenseService'
import { randomString } from '../../../utils/Random'
import { MediaFile } from '../../../api/CloudApi/types'
import { Panel } from '../../../types/Panel'

export type ListVideoModalProps = {
    show: boolean
    handleCloseFunction: () => void
    videoFiles: Array<MediaFile>
    savePanelFunction: (panel: Panel) => void
    cloudContext: CloudContext | undefined
}

export default function ListVideoModal(props: ListVideoModalProps) {
    const [selectedFile, setSelectedFile] = useState<MediaFile>()

    useEffect(() => {
        if (props.show && props.videoFiles.length === 1) {
            createPanel(props.videoFiles[0])
        }
    }, [props.videoFiles, props.show])

    const closeModal = () => {
        props.handleCloseFunction()
    }

    const createPanel = async (selectedFile: MediaFile) => {
        const videoPanel: Panel = {
            name: 'Video',
            panelType: PanelType.VIDEO,
            addedTimestamp: Date.now(),
            key: selectedFile!.fileName + randomString(),
            videoSettings: {
                fileName: selectedFile!.fileName,
                file: selectedFile!,
                projectId: props.cloudContext!.project,
                recordingSessionId: props.cloudContext!.recordingSession,
            },
        }
        props.savePanelFunction(videoPanel)

        closeModal()
    }

    const brokerDropdownItems = () => {
        return (props.videoFiles || []).map((file) => {
            return (
                <Dropdown.Item
                    key={file.fileName}
                    className="text-truncate ps-2 text-truncate w-100"
                    onClick={() => setSelectedFile(file)}
                >
                    {file.fileName}
                </Dropdown.Item>
            )
        })
    }

    return (
        <>
            <Modal size="lg" className="col-12" show={props.show} onHide={closeModal}>
                <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                    <Modal.Title className="lexend-regular text-light">Select video to play</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.videoFiles && props.videoFiles.length > 0 && (
                        <InputGroup size="sm">
                            <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                Video
                            </InputGroup.Text>
                            <Dropdown className="remotive-font-md w-75">
                                <Dropdown.Toggle className="remotive-font-md bg-transparent text-dark border-light-gray text-truncate px-2 rounded-1 w-75 text-start">
                                    {selectedFile?.fileName || 'Select video...'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="remotive-font-md pb-1 pt-1 w-75 remotive-dropdown-dark">
                                    {brokerDropdownItems()}
                                </Dropdown.Menu>
                            </Dropdown>
                        </InputGroup>
                    )}
                    {props.videoFiles.length === 0 && <p>No videos available for this recording</p>}
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        disabled={props.videoFiles.length === 0 || selectedFile === undefined}
                        className="btn remotive-btn remotive-btn-success"
                        onClick={() => createPanel(selectedFile!)}
                    >
                        Apply
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
