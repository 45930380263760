import { Route, Routes } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LicenseInfo, LicenseStatus } from 'remotivelabs-grpc-web-stubs'
import 'iframe-resizer/js/iframeResizer.contentWindow'

import './assets/css/App.css'
import useInterval from './hooks/useInterval'
import { BROKER_URL_LOCAL_STORAGE_KEY, getBrokerDetails, setBrokerApiKey, setBrokerUrl } from './utils/broker'

import { getCloudContext as getBrokerCloudContext, getLicenseInfo, isReachable } from './services/BrokerService'
import { CloudContext, ConnectionState, getBrokerPublicIpList, hasInternet } from './services/LicenseService'

import Monitor from './pages/Monitor'
import License from './pages/License'
import Configure from './pages/Configure'
import About from './pages/About'

import FixedNavbar from './components/navigation/FixedNavbar'
import { AuthenticatedUser } from './api/CloudApi/types'
import CloudApi from './api/CloudApi'
import BrokerIssueInfoModal from './components/modal/BrokerIssueInfoModal'
import SignInModal from './components/modal/SignInModal'
import OverlayModal from './components/modal/OverlayModal'
import { isDemo, isIframe, setLastProject } from './utils/CloudDetails'
import { useProductAnalyticsPage } from './utils/ProductAnalyticsPage'
import { useProductAnalyticsTracker } from './utils/ProductAnalyticsTracker'
import useScript from './hooks/useScript'
import AppStorage from './utils/AppStorage'
import { rootBackgroundColor } from './assets/DynamicProperties/colors'

function App() {
    const FLUID_DESIGN = true

    const [productAnalyticsSetupDone, setProductAnalyticsSetupDone] = useState(false)
    const [currentUser, setCurrentUser] = useState<AuthenticatedUser>()
    const [cloudContext, setCloudContext] = useState<CloudContext>()
    const [showSignInModal, setShowSignInModal] = useState<boolean>(false)
    const [showBrokerIssueInfoModal, setShowBrokerIssueInfoModal] = useState<boolean>(false)
    const [hasConnectedToBrokerOnce, setHasConnectedToBrokerOnce] = useState<boolean>(false)
    const [currentBrokerIssue, setCurrentBrokerIssue] = useState<'DOES_NOT_EXIST' | 'IDLE' | 'UNKNOWN'>('UNKNOWN')

    const [connectionState, setConnectionState] = useState<ConnectionState>({
        brokerHasEthernet: false,
        brokerPublicUrls: undefined,
        clientHasInternet: false,
        clientIsConnectedToBroker: false,
    })
    const checkingConnectionStateInProgress = useRef<boolean>(false)
    const [license, setLicense] = useState<LicenseInfo>()
    const [useStoredSession, setUseStoredSession] = useState<boolean>(false)
    const [isCloudBroker, setIsCloudBroker] = useState<boolean>(false)

    const [isAppInitialized, setIsAppInitialized] = useState<boolean>(false)

    useProductAnalyticsPage({ user: currentUser, cloudContext: cloudContext })
    const productAnalyticsTracker = useProductAnalyticsTracker({ user: currentUser, cloudContext: cloudContext })

    // Feedback form
    useScript(
        '//cdn.jsdelivr.net/npm/@betahuhn/feedback-js/dist/feedback-js.min.js',
        true,
        new Map([
            [
                'data-feedback-opts',
                '{ "endpoint": "https://remotive-feedback-handler-sglnqbpwoa-ez.a.run.app/feedback", "id": "RemotiveBroker", "emailField": true,"emailPlaceholder": "Email (optional)","inputPlaceholder": "Your feedback here... \\n\\nAdd your email if you don\'t want to be anonymous.","failedMessage": "Please try submitting your feedback again. If this keeps happening, contact us at support@remotivelabs.com","position": "right", "background": "white", "color": "#05315a", "primary": "#05315a","types": {"general": {"text": "Improvement","icon": "&bull;"},"bug": {"text": "Bug","icon": "&bull;"}}}',
            ],
        ]),
        !isIframe()
    )

    const getUserContext = async () => {
        try {
            const whoamiResponse = await CloudApi.whoami()
            return whoamiResponse.data
        } catch (err: any) {
            console.log(`Failed to fetch current user: ${err}`)
        }
        return undefined
    }

    const setDetailsFromQueryParams = () => {
        let params = new URLSearchParams(window.location.search)
        const brokerUrl = params.get('broker')
        setBrokerUrl(brokerUrl)
        const apiKey = params.get('api_key')
        setBrokerApiKey(apiKey)
        setLastProject(params.get('last_used_project'))
        removeSensitiveQueryParamsAndReload()
    }

    const removeSensitiveQueryParamsAndReload = () => {
        const [baseUrl, params] = window.location.href.split('?')
        if (params) {
            const allQueryParamKeyPairs = params.split('&')
            const safeQueryParamKeyPairs = allQueryParamKeyPairs.filter(
                (it) => !it.includes('api_key') && !it.includes('broker') && !it.includes('last_used_project')
            )
            if (safeQueryParamKeyPairs.length === allQueryParamKeyPairs.length) {
                // All query params are safe, do nothing
            } else {
                window.location.replace(`${baseUrl}?${safeQueryParamKeyPairs.join('&')}`)
            }
        }
    }

    const connectionStateCheck = async () => {
        const state = await checkConnectionStates()
        if (state) {
            setConnectionState(state)
        }
    }

    useEffect(() => {
        if (currentUser && !productAnalyticsSetupDone) {
            // Its fine that cloud context is not initialized here yet, it might not exist
            setProductAnalyticsSetupDone(true)
        }
    }, [currentUser])

    useEffect(() => {
        console.log(`Running with broker app version: ${process.env.REACT_APP_VERSION}`)

        document.documentElement.style.backgroundColor = rootBackgroundColor()

        const setupBrokerApp = async () => {
            setDetailsFromQueryParams()
            const isCloudBroker = getBrokerDetails().isCloudBroker()
            if (isCloudBroker) {
                setIsCloudBroker(isCloudBroker)
                const user = await getUserContext()
                if (user) {
                    setCurrentUser(user)
                    await connectionStateCheck()
                    setCloudContext(await fetchCloudContext())
                } else {
                    setShowSignInModal(true)
                }
            } else {
                await connectionStateCheck()
            }

            console.log("App is initialized, we're ready to rock!")
            setIsAppInitialized(true)
        }

        setupBrokerApp()

        return () => {
            console.log('App unmounted, probably crashed.')
        }
    }, [])

    useEffect(() => {
        if (isCloudBroker && connectionState.clientHasInternet) {
            const updateCloudContext = async () => {
                console.debug('Client has internet and the connection state was changed, fetching cloud context!')
                setCloudContext(await fetchCloudContext())
            }

            updateCloudContext()
        }
    }, [isCloudBroker, connectionState])

    useEffect(() => {
        if (isCloudBroker) {
            if (!connectionState.clientIsConnectedToBroker && !hasConnectedToBrokerOnce) {
                // If no connection to the broker
                console.debug('It is a cloud broker but the broker is not reachable, refer to remotive cloud console')
                setCurrentBrokerIssue('DOES_NOT_EXIST')
                setShowBrokerIssueInfoModal(true)
            } else if (!cloudContext) {
                // If no cloud context, broker does not have a recording and config uploaded
                console.debug('No cloud context, refer to remotive cloud console')
                setCurrentBrokerIssue('IDLE')
                setShowBrokerIssueInfoModal(true)
            } else if (cloudContext?.permissions && cloudContext.permissions.length <= 0) {
                //Cloudcontext exists but user is not permitted to use current broker
                console.debug('Cloud context exists but user lacks permission, refer to remotive cloud console')
                setCurrentBrokerIssue('DOES_NOT_EXIST')
                setShowBrokerIssueInfoModal(true)
            } else {
                // If everything is OK remove the broker issue modal completely
                AppStorage.setItem(AppStorage.CLOUD_CONTEXT_STORAGE_KEY, JSON.stringify(cloudContext))
                console.debug('Cloud context exists and user has permission, disable BrokerIssueModal')
                setShowBrokerIssueInfoModal(false)
            }
        }
    }, [isCloudBroker, cloudContext, connectionState])

    const fetchCloudContext = async (): Promise<CloudContext | undefined> => {
        const brokerCloudContext = await getBrokerCloudContext()
        if (brokerCloudContext) {
            const permissions = await CloudApi.getPermissionsForCurrentProject(brokerCloudContext.project)
            if (permissions && permissions.length > 0) {
                return {
                    project: brokerCloudContext.project,
                    recordingSession: brokerCloudContext.recordingSessionId,
                    organisation: brokerCloudContext.organisation,
                    permissions: permissions,
                    brokerConfigName: brokerCloudContext.brokerConfigName,
                }
            }
        }
        return undefined
    }

    // Ugly state fetching stuff, perhaps move this somewhere
    const checkConnectionStates = async () => {
        if (document.hidden) {
            console.log('Not checking connection when tab/doc not visible')
            return
        }
        console.log('Checking connection states...')
        if (!checkingConnectionStateInProgress.current) {
            let clientHasInternet = false
            let brokerHasEthernet = false
            let brokerPublicIp
            let isClientConnectedToBroker = false

            checkingConnectionStateInProgress.current = true

            try {
                clientHasInternet = await hasInternet()
            } catch (e: any) {
                clientHasInternet = false
            }
            try {
                isClientConnectedToBroker = await isReachable()
            } catch (e: any) {
                console.log(e)
                isClientConnectedToBroker = false
            }
            try {
                if (isClientConnectedToBroker) {
                    setHasConnectedToBrokerOnce(true)
                    const l = await getLicenseInfo()
                    if (license === undefined) {
                        setLicense(l)
                    }
                }
            } catch (e: any) {
                console.warn(`Failed to fetch license. Error: \n${JSON.stringify(e)}`)
            }
            try {
                if (isClientConnectedToBroker) {
                    const list = await getBrokerPublicIpList()

                    brokerPublicIp = list.length > 0 ? list : undefined
                    brokerHasEthernet = brokerPublicIp !== undefined
                }
            } catch (e: any) {
                console.warn(`Failed to fetch broker version for App. Error: \n${JSON.stringify(e)}`)
                brokerPublicIp = undefined
                brokerHasEthernet = false
            }
            // TODO - Optimise by checking if old state differs

            checkingConnectionStateInProgress.current = false

            const newState = {
                brokerHasEthernet: brokerHasEthernet,
                brokerPublicUrls: brokerPublicIp,
                clientHasInternet: clientHasInternet,
                clientIsConnectedToBroker: isClientConnectedToBroker,
            }
            if (
                newState.brokerHasEthernet === connectionState.brokerHasEthernet &&
                JSON.stringify(newState.brokerPublicUrls) === JSON.stringify(connectionState.brokerPublicUrls) &&
                newState.clientHasInternet === connectionState.clientHasInternet &&
                newState.clientIsConnectedToBroker === connectionState.clientIsConnectedToBroker
            ) {
                console.log('Connection state is the same')
                return undefined
            } else {
                console.log(`Returning new connection state ${JSON.stringify(newState)}`)
                return newState
            }
        } else {
            console.log('Getting connection state already in progress')
        }
    }

    useInterval(() => {
        connectionStateCheck()
    }, 7000)

    const isLicensed = () => {
        return license?.getStatus() === LicenseStatus.VALID
    }

    return (
        <div className="App lexend-regular p-1" style={{ background: rootBackgroundColor() }}>
            <FixedNavbar isCloudBroker={isCloudBroker} currentUser={currentUser} />
            <Routes>
                <Route
                    path="/"
                    element={
                        <Monitor
                            isAppInitialized={!showSignInModal && isAppInitialized}
                            isCloudBroker={isCloudBroker}
                            isFluidDesign={FLUID_DESIGN}
                            connectionState={connectionState}
                            setLicenseFunction={setLicense}
                            license={license}
                            isLicensed={isLicensed()}
                            cloudContext={cloudContext}
                            useStoredSession={useStoredSession}
                            setUseStoredSession={setUseStoredSession}
                            productAnalyticsTracker={productAnalyticsTracker}
                        />
                    }
                />
                <Route
                    path="/configure"
                    element={
                        <Configure
                            cloudContext={cloudContext}
                            isAppInitialized={!showSignInModal && isAppInitialized}
                            isCloudBroker={isCloudBroker}
                            isFluidDesign={FLUID_DESIGN}
                            connectionState={connectionState}
                            license={license}
                            setLicenseFunction={setLicense}
                            isLicensed={isLicensed()}
                        />
                    }
                />

                {!isCloudBroker && (
                    <Route
                        path="/license"
                        element={
                            <License
                                cloudContext={cloudContext}
                                isAppInitialized={!showSignInModal && isAppInitialized}
                                isCloudBroker={isCloudBroker}
                                isFluidDesign={FLUID_DESIGN}
                                connectionState={connectionState}
                                setLicenseFunction={setLicense}
                                license={license}
                                isLicensed={isLicensed()}
                            />
                        }
                    />
                )}
                {!isCloudBroker && (
                    <Route
                        path="/about"
                        element={
                            <About
                                cloudContext={cloudContext}
                                isAppInitialized={!showSignInModal && isAppInitialized}
                                isFluidDesign={FLUID_DESIGN}
                                isCloudBroker={isCloudBroker}
                                license={license}
                                isLicensed={isLicensed()}
                                setLicenseFunction={setLicense}
                                connectionState={connectionState}
                            />
                        }
                    />
                )}
            </Routes>
            <BrokerIssueInfoModal
                cloudContext={cloudContext}
                show={isAppInitialized && isCloudBroker && showBrokerIssueInfoModal && !showSignInModal}
                handleCloseFunction={() => setShowBrokerIssueInfoModal(false)}
                issue={currentBrokerIssue}
            />

            <SignInModal
                show={isAppInitialized && isCloudBroker && showSignInModal}
                handleCloseFunction={() => setShowSignInModal(false)}
            />

            <OverlayModal show={!isAppInitialized} text="Initializing..." />

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                bodyClassName={'lexend-regular remotive-font-md'}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default App
