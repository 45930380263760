interface PaginationProps {
    currentPage: number
    totalPages: number
    handlePageChange: Function
    itemsPerPage: number
}

const PAGES_IN_PAGE_SELECTOR = 7

export function Pagination(props: PaginationProps) {
    const pageButton = (index: number, className?: string) => {
        const pageNumber = index + 1
        const isCurrentPage = pageNumber === props.currentPage
        return (
            <button
                style={{ minWidth: '20px' }}
                className={`btn remotive-btn-no-bg rounded-2 p-0 m-0 remotive-btn-sm mx-1 ${className}`}
                onClick={() => props.handlePageChange(index + 1)}
            >
                <span className={`${isCurrentPage ? 'lexend-bold remotive-font-xl' : 'remotive-font-md'} `}>
                    {pageNumber}
                </span>
            </button>
        )
    }

    const paginationButton = (index: number, selectedPage: number, totalPages: number) => {
        const pageNumber = index + 1
        const pagesIncludingFirstAndLast = PAGES_IN_PAGE_SELECTOR + 2

        if (totalPages <= pagesIncludingFirstAndLast) {
            return pageButton(index)
        }

        // Always show the first and last page
        if (pageNumber === 1) {
            return <>{pageButton(index, 'me-3')}</>
        }
        if (pageNumber === totalPages) {
            return <>{pageButton(index, 'ms-3')}</>
        }

        // Always show the current page
        if (pageNumber === selectedPage) {
            return pageButton(index)
        }

        // Show half of pagesInCenter before and after the current page
        const pages = Math.floor(PAGES_IN_PAGE_SELECTOR / 2)
        if (Math.abs(pageNumber - selectedPage) <= pages) {
            return pageButton(index)
        }

        // When we are close to the endpoints, we should no longer rotate number. This will force the layout to always show pagesInCenter amount of buttons
        if (pageNumber < pagesIncludingFirstAndLast && selectedPage <= PAGES_IN_PAGE_SELECTOR - pages) {
            return pageButton(index)
        }

        if (pageNumber >= totalPages - PAGES_IN_PAGE_SELECTOR && selectedPage >= totalPages - pages) {
            return pageButton(index)
        }
    }

    return (
        <>
            <button
                disabled={props.currentPage === 1}
                className={`btn remotive-btn-no-bg rounded-2 p-1 px-2 m-0 remotive-btn-md mx-1`}
                onClick={() => props.handlePageChange(props.currentPage - 1)}
            >
                <span className="d-none d-md-block remotive-font-md">{'< Previous'}</span>
                <span className="d-md-none remotive-font-md text-decoration-underline">{'<'}</span>
            </button>
            <>
                {Array(props.totalPages)
                    .fill(0)
                    .map((_, index) => {
                        return paginationButton(index, props.currentPage, props.totalPages)
                    })}
            </>
            <button
                disabled={props.currentPage === props.totalPages}
                className={`btn remotive-btn-no-bg rounded-2 p-1 px-2 m-0 remotive-btn-md mx-1`}
                onClick={() => props.handlePageChange(props.currentPage + 1)}
            >
                <span className="d-none d-md-block remotive-font-md">{'Next >'}</span>
                <span className="d-md-none remotive-font-md text-decoration-underline">{'>'}</span>
            </button>
        </>
    )
}
