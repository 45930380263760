import { ReactElement } from 'react'

export interface SettingsPopoverItemProps {
    titleElement: ReactElement
    actionElement: ReactElement
}

export default function SettingsPopoverItem(props: SettingsPopoverItemProps) {
    const component = () => {
        return (
            <>
                <div
                    className="d-flex justify-content-between align-items-center rounded px-2 py-1 m-1 remotive-primary-20-background flex-truncate"
                    style={{ minWidth: 230 }}
                >
                    <div className="d-flex align-items-start flex-column flex-truncate">{props.titleElement}</div>
                    <div className="d-flex justify-content-center align-items-center flex-row align-items-center">
                        {props.actionElement}
                    </div>
                </div>
            </>
        )
    }

    return component()
}
