import { useState } from 'react'
import BrokersIcon from '@mui/icons-material/AccountTreeRounded'

import { getBrokerDetails } from '../../utils/broker'
import { Card, Modal } from 'react-bootstrap'
import BrokerDetailsContainer from './BrokerDetailsContainer'

interface BrokerDetailsInfoProps {
    asModal: boolean
}

function BrokerDetailsInfo(props: BrokerDetailsInfoProps) {
    const [show, setShow] = useState(false)

    const getBrokerDetailsBar = () => {
        return (
            <div className="d-flex justify-content-start">
                <button
                    className="border-0 rounded-4 remotive-primary-70-background p-2 ms-1 my-2 d-flex align-items-center"
                    style={{ opacity: 0.9 }}
                    onClick={() => setShow(!show)}
                >
                    <BrokersIcon className="mx-2 text-light" sx={{ fontSize: 18 }} />
                    <p className="m-0 text-light remotive-small me-2 lexend-bold">Broker details</p>
                </button>
            </div>
        )
    }

    return (
        <>
            <div className="mx-3">
                {props.asModal ? (
                    <>
                        <Modal show={show} size="lg" className="border-0 shadow-lg">
                            <BrokerDetailsContainer handleCloseFunction={() => setShow(false)} />
                        </Modal>
                    </>
                ) : (
                    <>
                        {' '}
                        {show && (
                            <Card style={{ zIndex: 9999 }} className="col-12 border-0 shadow-lg">
                                <BrokerDetailsContainer handleCloseFunction={() => setShow(false)} />
                            </Card>
                        )}
                    </>
                )}
            </div>
            {getBrokerDetailsBar()}
        </>
    )
}

export default BrokerDetailsInfo
