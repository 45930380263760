import { Card, Form, OverlayTrigger } from 'react-bootstrap'
import VideoPlayer from './VideoPlayer'
import { useEffect, useState } from 'react'
import format from 'format-duration'
import { PlaybackState } from '../../../api/BrokerApi/types'
import { VideoSyncStatus } from '../../../services/VideoSyncService'
import { Panel, VideoPanelSettings } from '../../../types/Panel'
import Close from '@mui/icons-material/CloseRounded'
import { MediaFile } from '../../../api/CloudApi/types'
import { CloudContext } from '../../../services/LicenseService'
import {
    CloseRounded,
    HighlightOffRounded,
    Settings,
    SpeakerNotesRounded,
    VolumeOffRounded,
    VolumeUpRounded,
    WarningRounded,
} from '@mui/icons-material'
import { SettingsPopoverItemProps } from '../../Popovers/SettingsPopover/SettingsPopoverItem'
import SettingsPopover from '../../Popovers/SettingsPopover'

interface VideoPanelCardProps {
    panelTitle: string
    panelKey: string
    playbackState: PlaybackState | undefined
    removePanelFunction: (panelKey: string) => void
    videoPanelSettings: VideoPanelSettings
    saveOffsetFunction: (mediaFile: MediaFile) => void
    cloudContext: CloudContext | undefined
}

function VideoPanelCard(props: VideoPanelCardProps) {
    const [videoLengthSeconds, setVideoLengthSeconds] = useState(0)
    const [videoSyncStatus, setVideoSyncStatus] = useState<VideoSyncStatus>()
    const [videoCurrentTime, setVideoCurrentTime] = useState(0)
    // This is the value that we store and use as the offset for video sync
    const videoOffsetSeconds = props.videoPanelSettings.file.videoOffsetSeconds
    // We need the offset as a string (temporarily) to allow manual user input
    const [desiredVideoOffsetSecondsInput, setDesiredVideoOffsetSecondsInput] = useState<string>(
        props.videoPanelSettings.file.videoOffsetSeconds.toFixed(2) //Round to two decimals
    )
    // We need the offset fromt the video slider (temporarily) to allow slider input
    const [desiredVideoOffsetSecondsSlider, setDesiredVideoOffsetSecondsSlider] = useState<number>(
        Math.round(props.videoPanelSettings.file.videoOffsetSeconds * 100) / 100 //Round to two decimals
    )

    // Settings
    const [showSettings, setShowSettings] = useState(false)
    const [syncModeEnabled, setSyncModeEnabled] = useState(false)
    const [soundMuted, setSoundMuted] = useState(true)

    useEffect(() => {
        if (videoSyncStatus && props.playbackState) {
            setVideoCurrentTime(isNaN(videoSyncStatus.videoCurrentTimeSecs) ? 0 : videoSyncStatus.videoCurrentTimeSecs)
            setVideoLengthSeconds(isNaN(videoSyncStatus.videoLengthSeconds) ? 0 : videoSyncStatus.videoLengthSeconds)
        }
    }, [videoSyncStatus])

    useEffect(() => {
        const desiredOffset = Math.round(desiredVideoOffsetSecondsSlider * 100) / 100
        setDesiredVideoOffsetSecondsInput(`${desiredOffset}`)
    }, [desiredVideoOffsetSecondsSlider])

    const popoverSettingsItem = (): Array<SettingsPopoverItemProps> => {
        return [
            {
                titleElement: (
                    <div className="text-start" title="Pause the recording to enable the synchronize button.">
                        <p className="m-0 remotive-font-sm lexend-bold">Video sync</p>
                        <p className="m-0 lexend-regular remotive-font-xs">
                            Will start <b>{Math.abs(Math.round(videoOffsetSeconds * 100) / 100)}s</b>{' '}
                            {videoOffsetSeconds > 0 ? 'after' : 'before'} recording
                        </p>
                        {props.playbackState && !props.playbackState.isPaused() && (
                            <p className="m-0 lexend-regular remotive-font-xs text-secondary">Pause to enable sync</p>
                        )}
                    </div>
                ),
                actionElement:
                    props.cloudContext && props.cloudContext.permissions.includes('project/recording/edit') ? (
                        <button
                            disabled={!props.playbackState?.isPaused()}
                            className="btn remotive-btn-primary remotive-btn-md remotive-font-xs border-0 ms-1"
                            onClick={() => setSyncModeEnabled(true)}
                        >
                            <p className="m-0">Sync</p>
                        </button>
                    ) : (
                        <></>
                    ),
            },
            {
                titleElement: (
                    <>
                        <p className="m-0 remotive-font-sm lexend-bold">Sound</p>
                    </>
                ),
                actionElement: (
                    <>
                        <button
                            onClick={() => setSoundMuted(!soundMuted)}
                            className="btn remotive-btn-primary remotive-btn-md remotive-font-xs border-0 ms-1"
                        >
                            <div className="d-flex align-items-center">
                                {!soundMuted && <VolumeUpRounded sx={{ fontSize: 16 }} />}
                                {soundMuted && <VolumeOffRounded sx={{ fontSize: 16 }} />}
                            </div>
                        </button>
                    </>
                ),
            },
        ]
    }

    const popover = () => {
        return <SettingsPopover show={showSettings} items={popoverSettingsItem()} />
    }

    const syncBanner = () => {
        return (
            <div className="d-flex justify-content-center" style={{ marginTop: 2, marginBottom: 1, height: 35 }}>
                <div
                    className="rounded-3 remotive-success-10-background ps-1 mx-1"
                    style={{ marginTop: 4, marginBottom: 3 }}
                >
                    <div className="d-flex align-items-center flex-grow-0 ps-2 pe-0">
                        <SpeakerNotesRounded sx={{ fontSize: 17 }} className="remotive-success-60-color me-3" />
                        <p className="d-none d-md-block mb-0 remotive-font-sm remotive-success-100-color me-2">
                            To sync, drag video slider or enter seconds
                        </p>
                        <p className="d-block d-md-none mb-0 remotive-font-sm remotive-success-100-color me-2">
                            Set delay
                        </p>
                        <Form.Control
                            style={{ width: 50 }}
                            type="text" // Keeps it a text input but only allows numbers
                            className="bg-white remotive-primary-40-border remotive-font-xs px-1 py-1 m-0 ms-1 me-2"
                            value={desiredVideoOffsetSecondsInput === undefined ? '' : desiredVideoOffsetSecondsInput}
                            onChange={(event) => {
                                console.log('desiredVideoOffsetSecondsInput changed', desiredVideoOffsetSecondsInput)
                                const value = event.target.value

                                // Allow only numbers, one decimal point, and at most two decimals
                                if (/^-?\d*[.,]?\d{0,2}$/.test(value)) {
                                    setDesiredVideoOffsetSecondsInput(value)
                                }
                            }}
                        />
                        {props.cloudContext &&
                            props.cloudContext.permissions.includes('project/recording/edit') &&
                            syncModeEnabled && (
                                <button
                                    disabled={!props.playbackState?.isPaused()}
                                    className="btn remotive-btn-primary remotive-btn-sm remotive-font-xs p-1 border-0"
                                    onClick={() => {
                                        const offset =
                                            desiredVideoOffsetSecondsInput === '' ||
                                            desiredVideoOffsetSecondsInput === '.'
                                                ? 0
                                                : Number(
                                                      Number(desiredVideoOffsetSecondsInput.replace(',', '.')).toFixed(
                                                          2
                                                      )
                                                  )
                                        props.videoPanelSettings.file.videoOffsetSeconds = offset
                                        props.saveOffsetFunction(props.videoPanelSettings.file)
                                        setSyncModeEnabled(false)
                                    }}
                                >
                                    Apply
                                </button>
                            )}
                        <button
                            disabled={!props.playbackState?.isPaused()}
                            className="btn remotive-btn-no-bg remotive-btn-sm remotive-font-xs border-0"
                            style={{ paddingRight: 0 }}
                            onClick={() => {
                                setSyncModeEnabled(false)
                            }}
                        >
                            <CloseRounded sx={{ fontSize: 15 }} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const videoDurationOrInfoText = () => {
        if (props.playbackState && props.playbackState.currentOffsetDurationMs() / 1000 - videoOffsetSeconds < 0) {
            return (
                <p className="remotive-font-sm m-0">
                    Starts in {(videoOffsetSeconds - props.playbackState.currentOffsetDurationMs() / 1000).toFixed(0)}s
                </p>
            )
        }

        return (
            <>
                <p className="remotive-font-sm m-0">
                    {format(videoCurrentTime * 1000)} / {format(Math.round(videoLengthSeconds * 1000))}
                    {props.playbackState &&
                        props.playbackState.currentOffsetDurationMs() / 1000 - videoOffsetSeconds >
                            videoLengthSeconds && <p className="remotive-font-xs text-secondary mb-0" style={{marginTop: 2}}>Video has ended</p>}
                </p>
            </>
        )
    }

    const titleAndTimeOrBanner = () => {
        if (syncModeEnabled) {
            return <>{syncBanner()}</>
        }
        return (
            <div className="d-flex d-inline-block align-items-center text-truncate flex-nowrap">
                <p className="remotive-font-sm m-1 p-1 text-start remotive-primary-60-color text-truncate w-100">
                    {props.videoPanelSettings.fileName}
                </p>
                <div className="lh-1">{videoDurationOrInfoText()}</div>
            </div>
        )
    }

    return (
        <>
            <Card className="shadow rounded border-0 m-1 mx-0 pb-2" style={{ height: '400px' }}>
                <div className={`d-flex justify-content-${syncModeEnabled ? 'center' : 'between'} p-0 m-0 flex-nowrap`}>
                    {titleAndTimeOrBanner()}
                    <div className="d-flex align-items-center flex-nowrap">
                        {!syncModeEnabled && (
                            <button className="btn remotive-btn-secondary remotive-btn-sm p-1 border-0">
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="left"
                                    show={showSettings}
                                    onToggle={(newState: boolean) => setShowSettings(newState)}
                                    overlay={popover()}
                                >
                                    <div className="d-flex align-items-center">
                                        <Settings sx={{ fontSize: 20 }} />
                                        <p className="m-0 ms-1 d-none d-lg-block">Settings</p>
                                    </div>
                                </OverlayTrigger>
                            </button>
                        )}
                        {!syncModeEnabled && (
                            <button
                                onClick={() => props.removePanelFunction(props.panelKey)}
                                className="btn remotive-btn-no-bg remotive-btn-sm"
                            >
                                <div className="d-flex align-items-center">
                                    <Close sx={{ fontSize: 24 }} />
                                </div>
                            </button>
                        )}
                    </div>
                </div>
                <div className="h-100">
                    {!props.videoPanelSettings?.file && <p>Loading video...</p>}
                    {props.videoPanelSettings?.file && (
                        <VideoPlayer
                            onVideoLoadedCallback={() => console.log('Video loaded')}
                            videoSettings={props.videoPanelSettings}
                            videoSourceType={props.videoPanelSettings.file.mimeType}
                            playbackState={props.playbackState}
                            videoStatusCallback={setVideoSyncStatus}
                            syncModeEnabled={syncModeEnabled}
                            desiredVideoOffsetSecondsSlider={desiredVideoOffsetSecondsSlider}
                            setDesiredVideoOffsetSecondsSlider={setDesiredVideoOffsetSecondsSlider}
                            videoOffsetSeconds={videoOffsetSeconds}
                            soundMuted={soundMuted}
                        />
                    )}
                </div>
                {videoSyncStatus?.problemDescription && (
                    <div>
                        <p className="m-0 remotive-font-xs text-secondary">{videoSyncStatus.problemDescription}</p>
                    </div>
                )}
            </Card>
        </>
    )
}

export default VideoPanelCard
